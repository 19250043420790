import React from 'react'
import { graphql } from 'gatsby'

import Layout from '~/components/Layout'
import Seo from '~/components/Seo'
import IntroSection from '~/components/IntroSection'

import * as classes from './Contact.module.scss'

const ContactPage = ({ data }) => {
  const contactPage = data.prismicContact.data

  const { seo_title: seoTitle, seo_description: seoDescription } = contactPage

  const introSectionProps = {
    title: contactPage.intro_title.text,
    textRaw: contactPage.intro_text.raw,
    link: {
      anchor: true,
      to: 'mailto:hello@southpawstudio.co.nz',
      text: 'Email us',
    },
    gatsbyImageData: {
      image: contactPage.intro_illustration.gatsbyImageData,
      alt: contactPage.intro_illustration.alt,
    },
  }

  return (
    <Layout>
      <Seo title={seoTitle?.text} description={seoDescription?.text} />
      <div className={classes.contactPage}>
        <IntroSection {...introSectionProps} />
      </div>
    </Layout>
  )
}

export default ContactPage

export const query = graphql`
  query contact {
    prismicContact {
      data {
        seo_description {
          text
        }
        seo_title {
          text
        }
        intro_illustration {
          gatsbyImageData(layout: FIXED)
          alt
        }
        intro_text {
          raw
        }
        intro_title {
          text
        }
      }
    }
  }
`
